<template>
  <b-modal id="modal-share-file-v3" no-close-on-backdrop hide-header hide-footer centered>
    <div class="share-modal-container">
        <div class="d-flex justify-content-between align-items-center">
            <span class="title-modal-share">Share</span>
            <img src="@/assets/images/icon/Close.svg" class="cursor-pointer" alt="" @click="close()">
        </div>
        <div class="my-2 selected-file-share">
            <img :src="require(`@/assets/images/icon/${item.icon}`)" alt/>
            <span class="ml-2 text-wrap-v3" data-toggle="tooltip" :title="item.file_name">{{ item.file_name }}</span>
            <div>
            </div>
        </div>
        <div
            id="emails-input-share-container"
            class="emails-input-share-container"
            :class="tagsEmail.length > 0 ? 'h-132' : ''"
            tabindex="-1">
            <input
                id="emails-input"
                type="text-area"
                class="emails-input"
                :class="tagsEmail.length > 0 ? '' : 'h16-v3'"
                placeholder="Enter participant’s email"
                v-model="inputModel"
                @focus="inputfocus()"
                @focusout="inputOutFocus()"
                v-on:keyup="showSuggest($event)"
                v-on:keydown.enter.prevent="checkInput($event)"
                v-on:keydown.tab.prevent="checkInput($event)">
            <ul class="suggest-box"></ul>
        </div>
        <div class="d-flex my-2 justify-content-between align-items-center">
            <b-dropdown id="dropdown-share-file-participant">
                <template #button-content>
                    <div class="d-flex align-items-center">
                        <span class="title-invite-wg-v3 mr-1">{{ selectedAccess.label }}</span>
                        <img src="@/assets/images/icon/chevDown.svg" alt="">
                    </div>
                </template>
                <b-dropdown-item v-for="(access,ia) in accessTypes" :key="ia" @click="changeAccess(ia)">
                    <div class="d-flex justify-content-start">
                        <img v-if="access.selected" src="@/assets/images/icon/Checklist.svg" alt="">
                        <span v-else class="mx-2"></span>
                        <span class="title-invite-wg-v3 mx-3">{{ access.label }}</span>
                    </div>
                </b-dropdown-item>
            </b-dropdown>
            <span
                id="manage-access-shared"
                class="cursor-pointer text-manage-access-share"
                @click="shareOpen(item)">Manage access</span>
        </div>
        <div id="horizontal-share-file">
            <hr class="horizontal-shared-file">
        </div>
        <div id="get-link-file-container-v3" class="mt-3 justify-content-start align-items-start flex-column">
            <span class="title-modal-share">Share via link</span>
            <div class="my-2 justify-content-start align-items-center">
                <label class="switch-share-v3">
                    <input
                        id="checkShareValue"
                        type="checkbox"
                        v-model="enableds"
                        @click="showLink()">
                    <span class="slider-share-v3 round-share-v3"></span>
                </label>
                <span class="ml-2 label-toggle-share">{{ enableds == false ? 'Disabled' : 'Enabled'}}</span>
            </div>
            <div class="share-link-file mt-2">
                <input
                    class="shared-link"
                    type="text"
                    :value="linkFile"
                    v-on:keydown="noBackSpace($event)"
                    v-if="enableds">
                <img
                    v-if="enableds"
                    src="@/assets/images/icon/fi-rr-copy-alt.svg"
                    alt=""
                    @click="copyLink(linkFile)"
                    data-toggle="tooltip"
                    title="Copy Link">
            </div>
        </div>
        <div id="send-inv-share-file" class="mt-2">
            <!-- <input
                type="text"
                class="input-msg-add-participant mb-4"
                placeholder="Message (Optional)"> -->
            <div class="d-flex justify-content-end align-items-center">
                <button class="skip-add-btn-v3" @click="close()">Cancel</button>
                <button
                  class="ml-3 inv-btn-participant-v3"
                  @click="sendBtn()"
                  :disabled="this.tagsEmail.length <= 0">Send</button>
            </div>
        </div>
    </div>
  </b-modal>
</template>

<script>
export default {
    data() {
        return {
            tagsEmail: [],
            // isChecked: false,
            inputModel: '',
            accessTypes : [
                {
                    id : "View",
                    label : "Viewer",
                    selected : true
                },
                {
                    id : "Write",
                    label : "Editor",
                    selected : false
                }
            ],
        }
    },
    computed: {
        item(){
            return this.$store.getters["storage/item"];
        },
        linkFile(){
            return location.protocol + '//' + location.host+"/shared/?key="+this.item._id+"&directory="+this.item.directory
        },
        selectedAccess(){
            return this.accessTypes.filter(v=>v.selected)[0]
        },
        enableds:{
            cache: false,
            get(){
                return this.$store.getters["storage/publicShare"];
            },
            set(){
                const enbld = this.$store.getters["storage/publicShare"];
                this.$store.commit("storage/SET_PUBLIC_SHARE",enbld);
            }
        }
    },
    methods: {
        shareOpen(item){
            this.$store.commit("storage/SET_SHOW_PROPERTIES",false);
            this.$store.commit("share/SET_SHOW_PROPERTIES",false);
            this.$store.commit("storage/SET_ITEM",item);
            this.$store.dispatch("storage/fileretrieve",{_id : item._id,directory:item.directory})
            this.$bvModal.hide('modal-share-file-v3')
            this.$router.push({name: 'FileShared', query: {id: item._id, d: item.directory }})
        },
        inputfocus () {
            var container = document.getElementById('emails-input-share-container')
            var linkContainer = document.getElementById('get-link-file-container-v3')
            var sendInvContainer = document.getElementById('send-inv-share-file')
            var manageAccess = document.getElementById('manage-access-shared')
            var hrLine = document.getElementById('horizontal-share-file')
            container.style.height = '132px'
            container.style.border = '2px solid #00AAFF'
            linkContainer.style.display = 'none'
            manageAccess.style.display = 'none'
            hrLine.style.display = 'none'
            sendInvContainer.style.display = 'block'
        },
        inputOutFocus () {
            var container = document.getElementById('emails-input-share-container')
            // var linkContainer = document.getElementById('get-link-file-container-v3')
            // var sendInvContainer = document.getElementById('send-inv-share-file')
            // var manageAccess = document.getElementById('manage-access-shared')
            // var hrLine = document.getElementById('horizontal-share-file')
            if(this.tagsEmail.length == 0){
                // container.style.height = '52px'
                container.style.border = '1px solid #E9EAEA'
                // linkContainer.style.display = 'block'
                // manageAccess.style.display = 'block'
                // hrLine.style.display = 'block'
                // sendInvContainer.style.display = 'none'
            } else {
                // container.style.height = '132px'
                container.style.border = '1px solid #E9EAEA'
                // linkContainer.style.display = 'none'
                // manageAccess.style.display = 'none'
                // hrLine.style.display = 'none'
                // sendInvContainer.style.display = 'block'
            }
        },
        showSuggest (e) {
            var value = this.inputModel
            this.removeElements()
            if (this.isValidEmail(value) && value !== '') {
                const suggestEmail = document.createElement('li')
                suggestEmail.classList.add('suggest-email')
                suggestEmail.style.cursor = 'pointer'
                suggestEmail.addEventListener('click', this.displayEmail, false)
                suggestEmail.innerHTML = value
                document.querySelector('.suggest-box').appendChild(suggestEmail)
            }
        },
        displayEmail (e) {
            this.tagsEmail.push(e.target.innerText)
            this.addTags()
            this.removeElements()
            this.inputModel = ''
        },
        removeElements () {
            var items = document.querySelectorAll('.suggest-email')
            items.forEach((item) => {
                item.remove()
            })
        },
        checkInput (e) {
            this.tagsEmail.push(this.inputModel)
            this.addTags()
            this.inputModel = ''
        },
        addTags () {
            var inputContainer = document.getElementById('emails-input-share-container')
            this.reset()
            this.tagsEmail.slice().reverse().forEach((tag) => {
                var input = this.isValidEmail(tag) ? this.createTag(tag) : this.errorTag(tag)
                inputContainer.prepend(input)
            })
        },
        reset () {
            document.querySelectorAll('.tag').forEach((tag) => {
                tag.parentElement.removeChild(tag)
            })
        },
        createTag (label) {
            const div = document.createElement('div')
            div.setAttribute('class', 'tag')
            const span = document.createElement('span')
            span.innerHTML = label
            const closeBtn = document.createElement('i')
            closeBtn.setAttribute('class', 'icon-close-v3')
            closeBtn.setAttribute('data-item', label)
            closeBtn.innerHTML = '&times;'
            div.appendChild(span)
            div.appendChild(closeBtn)

            var self = this
            closeBtn.addEventListener('click', function (e) {
                if (e.target.tagName === 'I') {
                    const value = e.target.getAttribute('data-item')
                    const index = self.tagsEmail.indexOf(value)
                    self.tagsEmail = [...self.tagsEmail.slice(0, index), ...self.tagsEmail.slice(index + 1)]
                    self.addTags()
                }
            })
            return div
        },
        errorTag (label) {
            const div = document.createElement('div')
            div.setAttribute('class', 'tag error-tag')
            const span = document.createElement('span')
            span.innerHTML = label
            const closeBtn = document.createElement('i')
            closeBtn.setAttribute('class', 'icon-close-error-v3')
            closeBtn.setAttribute('data-item', label)
            closeBtn.innerHTML = '&times;'
            div.appendChild(span)
            div.appendChild(closeBtn)

            var self = this
            closeBtn.addEventListener('click', function (e) {
                if (e.target.tagName === 'I') {
                    const value = e.target.getAttribute('data-item')
                    const index = self.tagsEmail.indexOf(value)
                    self.tagsEmail = [...self.tagsEmail.slice(0, index), ...self.tagsEmail.slice(index + 1)]
                    self.addTags()
                }
            })
            return div
        },
        isValidEmail (email) {
            if (email === '') return false
            const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return regexEmail.test(email)
        },
        changeAccess(idx){
            this.accessTypes = this.accessTypes.map((v,i)=>{
                return {
                    label: v.label,
                    selected: i == idx,
                    id: v.id
                }
            })
        },
        sendBtn () {
            var sendVerify = true
            if (this.tagsEmail.length > 0) {
                this.tagsEmail.forEach((tag) => {
                    if (!this.isValidEmail(tag)) {
                        sendVerify = false
                    }
                })
            }
            if (sendVerify) {
                var params = {
                    files_id : this.item._id,
                    directory : this.item.directory,
                    users_id: this.tagsEmail.map((v) => {
                        return {
                            email: v,
                            access_type : this.selectedAccess.id
                        }
                    })
                }
                this.$store.dispatch("share/add_user",params)
                .then(() => {
                    this.$toasted.show('File shared successfully.',{ 
                        theme: "toasted-primary", 
                        position: "bottom-center", 
                        duration : 2000
                    })
                    this.$bvModal.hide('modal-share-file-v3')
                    this.tagsEmail = []
                })
                .catch(() => {
                    this.$toasted.show('File failed to share. Make sure your internet is connected, then try again.',{ 
                        theme: "toasted-primary", 
                        position: "bottom-center", 
                        duration : 2000
                    })
                    this.$bvModal.hide('modal-share-file-v3')
                    this.tagsEmail = []
                })
            } else {
                this.$toasted.show('Invalid email input.',{ 
                    theme: "toasted-primary", 
                    position: "bottom-center", 
                    duration : 2000
                })
            }
        },
        showLink(){
            var checkBox = document.getElementById('checkShareValue')
            if(checkBox.checked){
                this.$store.dispatch("share/public_share",
                    {
                        files_id : this.item._id,
                        directory : this.item.directory
                    }
                )
                .then(()=>{
                    // this.isChecked = true
                    this.$store.commit("storage/SET_PUBLIC_SHARE",true);
                    this.$store.dispatch("storage/fileretrieve",{_id : this.item._id,directory:this.item.directory})
                })
            } else {
                this.$store.dispatch("share/disable_public_share",
                    {
                        files_id : this.item._id,
                        directory : this.item.directory
                    }
                ).then(()=>{
                    // this.isChecked = false
                    this.$store.commit("storage/SET_PUBLIC_SHARE",false);
                    this.$store.dispatch("storage/fileretrieve",{_id : this.item._id,directory:this.item.directory})
                })
            }
        },
        noBackSpace(e) {
            if(e.key != 'ArrowLeft' && e.key != 'ArrowRight' && e.key != 'Shift'){
                e.preventDefault()
            }
        },
        copyLink(value) {
            navigator.clipboard.writeText(value)
            this.$toasted.show('Link copied.',{ 
                theme: "toasted-primary", 
                position: "bottom-center", 
                duration : 2000
            })
        },
        close() {
            this.$bvModal.hide('modal-share-file-v3')
            this.tagsEmail = []
        }
    }
}
</script>

<style>
#send-inv-share-file{
    display: none;
}
#dropdown-share-file-participant{
    width: fit-content;
}
#dropdown-share-file-participant button{
    background: #FFFFFF !important;
    color: #262A2C !important;
    border: none;
    margin: 12px 0 12px 0;
    padding: 0;
}

hr.horizontal-shared-file{
    border: 1px solid #E8E8E8;
    margin: 0;
}
.text-manage-access-share{
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -1px;
    font-weight: 400;
    color: #00AAFF;
}

.emails-input-share-container{
    border: 1px solid #E9EAEA;
    padding: 16px;
    border-radius: 4px;
    display: flex;
    width: 384px;
    height: 52px;
    background: #FFFFFF;
    flex-wrap: wrap;
    overflow-y: scroll;
    overflow-x: hidden;
}
.h-132{
    height: 132px;
}
.h16-v3{
    height: 16px;
}

.emails-input-share-container:focus{
    border: 2px solid #00AAFF;
}
.emails-input-share-container .tag{
  padding: 4px 8px;
  border: none;
  display: flex;
  align-items: center;
  border-radius: 4px;
  background: #E9F5FB;
  margin: 0 8px 8px 0;
  cursor: default;
  font-size: 16px;
  line-height: 24px;
  color: #262A2C;
  font-weight: 400;
  letter-spacing: -1px;
}
.emails-input-share-container .error-tag{
  background: #FBE9E9 !important;
}

.share-modal-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.title-modal-share{
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -1px;
    font-weight: 400;
    color: #262A2C;
}
.selected-file-share{
    background: #F6F6FC;
    border-radius: 64px;
    padding: 8px 16px;
    width: fit-content;
    display: flex;
}
.selected-file-share span{
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -1px;
    font-weight: 300;
    color: #262A2C;
}
.text-wrap-v3{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 320px;
}
.share-link-file{
    position: relative;
}
.share-link-file img{
    position: absolute;
    top: 15px;
    right: 17px;
    width: 20px;
    cursor: pointer;
}
.shared-link{
    min-width: auto;
    width: 384px;
    height: 52px;
    padding: 16px 55px 16px 16px;
    border: 1px solid #E9EAEA;
    border-radius: 4px;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -1px;
    font-weight: 300;
    color: #262A2C;
}
.shared-link:focus{
    outline: none;
}

/* Toggle switch */
.switch-share-v3 {
  position: relative;
  /* display: inline-block; */
  width: 51px;
  height: 31px;
}

/* Hide default HTML checkbox */
.switch-share-v3 input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider-share-v3 {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(120, 120, 128, 0.16);
  -webkit-transition: .4s;
  transition: .4s;
}

.slider-share-v3:before {
  position: absolute;
  content: "";
  height: 27px;
  width: 27px;
  left: 3px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.switch-share-v3 input:checked + .slider-share-v3 {
  background-color: #34C759;
}

.switch-share-v3 input:focus + .slider-share-v3 {
  box-shadow: 0 0 1px #34C759;
}

.switch-share-v3 input:checked + .slider-share-v3:before {
  -webkit-transform: translateX(27px);
  -ms-transform: translateX(27px);
  transform: translateX(18px);
}

/* Rounded sliders */
.slider-share-v3.round-share-v3 {
  border-radius: 34px;
}

.slider-share-v3.round-share-v3:before {
  border-radius: 50%;
}
.label-toggle-share{
    position: relative;
    color: #919394;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -1px;
    font-weight: 300;
    top: 7px;
}
</style>